<template>
  <component :is="resolveLayout">
    <router-view></router-view>
  </component>
</template>

<script>
import {computed} from '@vue/composition-api'
import {useRouter} from '@/utils'
import LayoutBlank from '@/layouts/Blank.vue'
import LayoutContent from '@/layouts/Content.vue'

export default {
  components: {
    LayoutBlank,
    LayoutContent,
  },
  metaInfo() {
    return {
      title: 'FantasyKombat',
      meta: [
        {property: 'og:url', content: 'https://www.fantasykombat.com/'},
      ],
    }
  },
  setup() {
    const {route} = useRouter()

    const resolveLayout = computed(() => {
      // Handles initial route
      if (route.value.name === null) return null

      if (route.value.meta.layout === 'blank') return 'layout-blank'

      return 'layout-content'
    })

    return {
      resolveLayout,
    }
  },
  data() {
    return {isMobile: false}
  },
  mounted() {
    this.onResize()
  },
  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 600 || window.innerHeight < 600
      if (this.isMobile) {
        document.body.classList.remove('desktop')
        document.body.classList.add('mobile')
      } else {
        document.body.classList.remove('mobile')
        document.body.classList.add('desktop')
      }
    },
  },
}
</script>
