import Vue from 'vue'
import VueRouter from 'vue-router'
import dbFunctions from '@/db'

Vue.use(VueRouter)

const routes = [
  {
    path: '/join',
    redirect: '/beta-access',
  },
  {
    path: '/',
    name: 'landing',
    component: () => import('@/views/LandingPage.vue'),
    meta: {
      layout: 'blank',
      public: true,
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/Dashboard.vue'),
  },
  {
    path: '/account-settings',
    name: 'account-settings',
    component: () => import('@/views/pages/account-settings/AccountSettings.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/pages/Login.vue'),
    meta: {
      layout: 'blank',
      public: true,
    },
  },
  {
    path: '/password-reset',
    name: 'password-reset',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/pages/PasswordReset.vue'),
    meta: {
      layout: 'blank',
      public: true,
    },
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/pages/Register.vue'),
    props: route => ({leagueCode: route.query.leagueCode}),
    meta: {
      layout: 'blank',
      public: true,
    },
  },
  {
    path: '/join',
    name: 'join',
    component: () => import('@/views/pages/Register.vue'),
    props: route => ({leagueCode: route.query.leagueCode}),
    meta: {
      layout: 'blank',
      public: true,
    },
  },
  {
    path: '/onboard',
    name: 'onboard',
    component: () => import('@/views/pages/Onboard.vue'),
  },
  {
    path: '/my-team',
    name: 'my-team',
    component: () => import('@/views/pages/TeamView.vue'),
  },
  {
    path: '/edit-team',
    name: 'edit-team',
    component: () => import('@/views/pages/TeamEdit.vue'),
  },
  {
    path: '/advisor',
    name: 'advisor',
    component: () => import('@/views/pages/Advisor.vue'),
  },
  {
    path: '/fight-tournaments',
    name: 'fight-tournaments',
    component: () => import('@/views/pages/FightTorno.vue'),
    meta: {
      public: true,
    },
  },
  {
    path: '/tournaments/ufc-fight-night/:eventId',
    name: 'ufc-fight',
    component: () => import('@/views/pages/tournaments-view/UFCEventView.vue'),
    meta: {
      public: true,
    },
  },
  {
    path: '/tournaments/beta-ufc-fight',
    name: 'next-beta-ufc-fight',
    component: () => import('@/views/pages/tournaments-view/BetaNextUFCPickEm.vue'),
  },
  {
    path: '/tournaments/ufc-fight',
    name: 'next-ufc-fight',
    component: () => import('@/views/pages/tournaments-view/UFCEventView.vue'),
    meta: {
      public: true,
    },
  },
  {
    path: '/tournaments/next-arcade-fight',
    name: 'arcade-tournaments',
    component: () => import('@/views/pages/tournaments-view/ArcadeView.vue'),
  },
  {
    path: '/fight-arcade',
    name: 'fight-arcade',
    component: () => import('@/views/pages/FightArcade.vue'),
  },
  {
    path: '/fight-arcade-main',
    name: 'fight-arcade-main',
    component: () => import('@/views/pages/FightArcadeFull.vue'),
  },
  {
    path: '/leagues',
    name: 'leagues',
    component: () => import('@/views/pages/Leagues.vue'),
  },
  {
    path: '/claim-winnings',
    name: 'claim-winnings',
    component: () => import('@/views/pages/ClaimWinnings.vue'),
  },
  {
    path: '/past-winnings',
    name: 'past-winnings',
    component: () => import('@/views/pages/PastWinnings.vue'),
  },
  {
    path: '/edit-league',
    name: 'edit-leagues',
    component: () => import('@/views/pages/LeagueEdit.vue'),
  },
  {
    path: '/view-league',
    name: 'view-league',
    component: () => import('@/views/pages/LeagueView.vue'),
  },
  {
    path: '/recruit-fighters',
    name: 'recruit-fighters',
    component: () => import('@/views/pages/RecruitFighters.vue'),
  },
  {
    path: '/scout-fighters',
    name: 'scout-fighters',
    component: () => import('@/views/pages/ScoutFighters.vue'),
  },
  {
    path: '/leaderboards',
    name: 'leaderboards',
    component: () => import('@/views/pages/Leaderboards.vue'),
  },
  {
    path: '/career-mode',
    name: 'career-mode',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/pages/CareerMode.vue'),
  },
  {
    path: '/wallet',
    name: 'wallet',
    component: () => import('@/views/pages/ConnectWallet.vue'),
  },
  {
    path: '/powerups',
    name: 'powerups',
    component: () => import('@/views/pages/UfcStrike.vue'),
  },
  {
    path: '/ar-viewer',
    name: 'ar-viewer',
    component: () => import('@/views/pages/ArViewer.vue'),
    meta: {
      layout: 'blank',
      public: true,
    },
  },
  {
    path: '/chat',
    name: 'chat',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/pages/ChatFullscreen.vue'),
    meta: {
      layout: 'Chat',
      public: true,
    },
  },
  {
    path: '/connect-wallet',
    name: 'connect-wallet',
    component: () => import('@/views/pages/ConnectWallet.vue'),
  },
  {
    path: '/contact-us',
    name: 'contact-us',
    component: () => import('@/views/pages/ContactUs.vue'),
    meta: {
      public: true,
    },
  },
  {
    path: '/how-to-play',
    name: 'how-to-play',
    component: () => import('@/views/info/HowToPlay.vue'),
    meta: {
      public: true,
    },
  },
  {
    path: '/what-is-solana-blockchain',
    name: 'what-is-solana-blockchain',
    component: () => import('@/views/info/WhySolana.vue'),
    meta: {
      public: true,
    },
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('@/views/pages/LogOut.vue'),
    meta: {
      layout: 'blank',
      public: true,
    },
  },
  {
    path: '/coming-soon',
    name: 'coming-soon',
    component: () => import('@/views/pages/ComingSoon.vue'),
    meta: {
      layout: 'blank',
      public: true,
    },
  },
  {
    path: '/beta-access',
    name: 'beta-signup',
    component: () => import('@/views/pages/BetaSignup.vue'),
    props: route => ({leagueCode: route.query.leagueCode}),
    meta: {
      public: true,
    },
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/pages/PrivacyPolicy.vue'),
    meta: {
      public: true,
    },
  },
  {
    path: '/terms-of-service',
    name: 'terms-of-service',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/pages/TermsOfService.vue'),
    meta: {
      public: true,
    },
  },

  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error.vue'),
    meta: {
      public: true,
    },
  },
  {
    path: '/deck',
    name: 'deck',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/pages/Deck.vue'),
    meta: {
      layout: 'blank',
      public: true,
    },
  },
  {
    path: '/fkadmin',
    name: 'admin-dashboard',
    component: () => import('@/views/admin/AdminDashboard.vue'),
    meta: {
      admin: true,
    },
  },
  {
    path: '/fkadmin/members',
    name: 'members',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/admin/Members.vue'),
    meta: {
      admin: true,
    },
  },
  {
    path: '/fkadmin/bots',
    name: 'bots',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/admin/Bots.vue'),
    meta: {
      admin: true,
    },
  },
  {
    path: '/fkadmin/nfts',
    name: 'nfts',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/admin/NftAdmin.vue'),
    meta: {
      admin: true,
    },
  },
  {
    path: '/fkadmin/prizeClaims',
    name: 'prizeClaims',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/admin/PrizeClaims.vue'),
    meta: {
      admin: true,
    },
  },
  {
    path: '/fkadmin/picks',
    name: 'picks',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/admin/Picks.vue'),
    meta: {
      admin: true,
    },
  },
  {
    path: '/fkadmin/managers',
    name: 'admin-managers',
    component: () => import('@/views/admin/AdminManagers.vue'),
    meta: {
      admin: true,
    },
  },
  {
    path: '/403',
    name: '403',
    component: () => import('@/views/403.vue'),
    meta: {
      public: true,
    },
  },
  {
    path: '/demo-video',
    redirect: '/',
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  const user = JSON.parse(localStorage.getItem('user'))
  if (to.matched.some(record => record.meta.admin)) {
    if (user.profile?.admin) {
      if (dbFunctions.checkAdmin(user)) {
        next()
      } else {
        router.push('/error-404')
      }
    } else {
      router.push('/error-404')
    }
  } else if (to.matched.some(record => record.meta.public)) {
    next()
  } else if (!user) {
    router.push('/join')
  } else {
    if (to.name === 'logout') {
      router.push('/dashboard')
    }
    next()
  }
})

router.onError(error => {
  if (/loading chunk \d* failed./i.test(error.message)) {
    console.log('Loading chunk failed..old cached? Reloaded')
    window.location.reload()
  }
})

export default router
