<template>
  <v-app>
       <div v-if="siteData.siteStatus" class="site-status" :class="siteData.siteStatusClass">
        {{ siteData.siteStatusDesc }}
      </div>
    <!-- Left Content -->
    <v-app-bar-nav-icon
      fixed
      class="d-block d-lg-none me-1"
      @click="isDrawerOpen = !isDrawerOpen"
    ></v-app-bar-nav-icon>
    <vertical-nav-menu :is-drawer-open.sync="isDrawerOpen"></vertical-nav-menu>
    <v-app-bar
      app
      flat
      absolute
      color="transparent"
    >
      <div class="boxed-container w-full">
        <div class="d-flex align-center ">
          <v-spacer></v-spacer>
          <GuestMenu v-if="!user.isLoggedin"></GuestMenu>
          <div
            v-else
            id="game-menu"
            clas="mr-1"
          >
            <router-link to="/my-team" v-if="user.profile.dapperAddress">
              <v-list-item>
                <v-avatar
                  :size="gAvatar"
                  class="white--text font-weight-medium me-2"
                >
                  <v-img :src="require('@/assets/images/misc/FKpoints.png')"></v-img>
                </v-avatar>
                <div class="d-flex align-center flex-grow-1 flex-wrap">
                  <div class="me-1">
                    <div class="font-weight-semibold">
                      <span class="text--primary text-base me-1">{{ myPowerPoints }}</span>
                    </div>
                    <v-list-item-subtitle class="text-xs">
                      Points
                    </v-list-item-subtitle>
                  </div>
                </div>
              </v-list-item>
            </router-link>
            <router-link v-else to="/my-team">
              <v-list-item>
                <v-avatar
                  :size="gAvatar"
                  class="white--text font-weight-medium me-2"
                >
                  <v-img :src="require('@/assets/images/misc/FKpoints.png')"></v-img>
                </v-avatar>
                <div class="d-flex align-center flex-grow-1 flex-wrap">
                  <div class="me-1">
                    <div class="font-weight-semibold">
                      <span class="text--primary text-base me-1">{{ myPoints }}</span>
                    </div>
                    <v-list-item-subtitle class="text-xs">
                      PowerUps
                    </v-list-item-subtitle>
                  </div>
                </div>
              </v-list-item>
            </router-link>
            <router-link to="/my-team" class="d-none">
              <v-list-item>
                <v-avatar
                  :size="gAvatar"
                  class="white--text font-weight-medium me-2"
                >
                  <v-img :src="fighterIcon"></v-img>
                </v-avatar>
                <div class="d-flex align-center flex-grow-1 flex-wrap">
                  <div class="me-1">
                    <div class="font-weight-semibold">
                      <span
                        v-if="coinsLoaded"
                        class="text--primary text-base me-1"
                      >{{
                          teamValue|toCurrencyNoDec
                        }}</span>
                      <span
                        v-else
                        class="text--primary text-base me-1"
                      >...</span>
                    </div>
                    <v-list-item-subtitle class="text-xs">
                      Team Value
                    </v-list-item-subtitle>
                  </div>
                </div>
              </v-list-item>
            </router-link>
            <router-link to="/connect-wallet">
              <v-list-item>
                <v-avatar
                  :size="gAvatar"
                  class="white--text font-weight-medium me-2"
                >
                  <v-img :src="coinIconGold"></v-img>
                </v-avatar>
                <div class="d-flex align-center flex-grow-1 flex-wrap">
                  <div class="me-1">
                    <div class="font-weight-semibold">
                      <span
                        v-if="coinsLoaded"
                        class="text--primary text-base me-1"
                      >{{
                          myGoldCoins|toCurrency
                        }}</span>
                      <span
                        v-else
                        class="text--primary text-base me-1"
                      >...</span>
                    </div>
                    <v-list-item-subtitle class="text-xs hidden-sm-and-down">
                      Gold KombatCoins
                    </v-list-item-subtitle>
                    <v-list-item-subtitle class="text-xs hidden-md-and-up">
                      GKC
                    </v-list-item-subtitle>
                  </div>
                </div>
              </v-list-item>
            </router-link>
            <router-link to="/connect-wallet">
              <v-list-item class="mr-0">
                <v-avatar
                  :size="gAvatar"
                  class="white--text font-weight-medium me-2"
                >
                  <v-img :src="coinIconSilver"></v-img>
                </v-avatar>
                <div class="d-flex align-center flex-grow-1 flex-wrap">
                  <div class="me-1">
                    <div class="font-weight-semibold">
                      <span
                        v-if="coinsLoaded"
                        class="text--primary text-base me-1"
                      >{{
                          mySilverCoins|toCurrencyNoDec
                        }}</span>
                      <span
                        v-else
                        class="text--primary text-base me-1"
                      >...</span>
                    </div>
                    <v-list-item-subtitle class="text-xs hidden-sm-and-down">
                      Silver KombatCoins
                    </v-list-item-subtitle>
                    <v-list-item-subtitle class="text-xs hidden-md-and-up">
                      SKC
                    </v-list-item-subtitle>
                  </div>
                </div>
              </v-list-item>
            </router-link>
          </div>
          <app-bar-user-menu
            v-if="user.isLoggedin"
            class="ml-4"
          ></app-bar-user-menu>
        </div>
      </div>
    </v-app-bar>

    <v-main :class="user.isLoggedin === 'true' ? 'backgroundDefault' : 'backgroundOctagon' ">
      <div class="app-content-container boxed-container px-4">
        <v-scroll-y-transition mode="out-in">
          <slot></slot>
        </v-scroll-y-transition>
      </div>
    </v-main>

    <v-footer
      color="transparent"
      class="pa-0"
    >
      <div class="w-full mb-3 mt-3 mr-2">
        <div class="mx-2 d-flex justify-end">
                <span>
            <a
              href="https://twitter.com/Fantasy_Kombat"
              target="_blank"
              class="text-decoration-none text-xs ml-2"
            ><v-icon>{{ icons.mdiTwitter }}</v-icon></a>
          </span>
          <span>
            <a
              href="https://www.instagram.com/fantasykombat/"
              target="_blank"
              class="text-decoration-none text-xs ml-2 mr-2"
            ><v-icon>{{ icons.mdiInstagram }}</v-icon></a>
          </span>
          <span>
            <a
              href="https://support.fantasykombat.com/"
              target="_blank"
              class="text-decoration-none text-sm"
            >Support</a>
          </span>
          <span
            v-if="!user.isLoggedin"
            class="mr-4 ml-5"
          >
            <a
              href="https://support.fantasykombat.com/en/article/terms-of-service-qhnr7c/"
              class="text-decoration-none text-sm"
            >Terms of service</a>
          </span>
          <span
            v-if="!user.isLoggedin"
            clsss="mr-4"
          >
            <a
              href="https://support.fantasykombat.com/en/article/privacy-policy-hlxqnr/"
              class="text-decoration-none text-sm"
            >Privacy Policy</a>
          </span>
          <span class="d-sm-inline d-none copyright mr-2 ml-2 mt-1 text-sm">
            &copy; {{ new Date().getFullYear() }} <a
            href="https://www.FantasyKombat.com"
            class="text-decoration-none"
            target="_blank"
          >FantasyKombat</a>
          </span>
        </div>
      </div>
    </v-footer>
  </v-app>
</template>

<script>
import {ref} from '@vue/composition-api'
import {
  mdiBellOutline,
  mdiBattery,
  mdiCalendar,
  mdiTwitter,
  mdiInstagram,
} from '@mdi/js'
import VerticalNavMenu from './components/vertical-nav-menu/VerticalNavMenu.vue'
import AppBarUserMenu from './components/AppBarUserMenu.vue'
import GuestMenu from '@/layouts/components/GuestMenu.vue'
import dbFunctions from '@/db'
import db from '@/db/init'

export default {
  components: {
    GuestMenu,
    VerticalNavMenu,
    AppBarUserMenu,
  },
  setup() {
    const isDrawerOpen = ref(null)

    return {
      isDrawerOpen,
      icons: {
        mdiBellOutline,
        mdiBattery,
        mdiCalendar,
        mdiTwitter,
        mdiInstagram,
      },
      bellAlert: false,
    }
  },
  data() {
    return {
      user: {},
      gAvatar: 30,
      mySilverCoins: 0,
      myGoldCoins: 0,
      myPoints: this.$store.state.user?.profile?.totalPoints,
      myPowerPoints: this.$store.state.user?.profile?.totalCombined,
      teamValue: 0,
      coinsLoaded: false,
      fpIcon: require('@/assets/images/misc/FKpoints.png'),
      coinIconGold: require('@/assets/images/buy/KombatCoinGold.png'),
      coinIconSilver: require('@/assets/images/buy/KombatCoinSilver.png'),
      fighterIcon: require('@/assets/images/avatars/FantasyFighterCard.png'),
      refreshLoad: false,
      siteData: {},
    }
  },
  mounted() {
    this.deviceCheck()
    this.checkUser()
    window.addEventListener('onorientationchange', this.deviceCheck())
    window.addEventListener('resize', this.deviceCheck())

    db.collection('admin')
      .doc('A000000')
      .onSnapshot(doc => {
        this.siteData = doc.data()
      })
  },
  methods: {
    deviceCheck() {
      const viewportwidth = this.$vuetify.breakpoint.width

      // const portrait = window.matchMedia('(orientation: portrait)').matches
      if (viewportwidth <= 420) {
        this.gAvatar = 25
      } else if (viewportwidth <= 480) {
        this.gAvatar = 30
      } else if (viewportwidth <= 500) {
        this.gAvatar = 30
      } else if (viewportwidth <= 550) {
        this.gAvatar = 30

        // decrease for mobile landscape
      } else if (viewportwidth > 600 && this.$vuetify.breakpoint.mobile) {
        this.gAvatar = 25
      } else {
        this.gAvatar = 30
      }
    },
    checkUser() {
      if (this.$store.state?.user) {
        this.user = this.$store.state.user
      } else {
        this.user = {}
        this.user.isLoggedin = false
      }

      if (this.user.isLoggedin) {
        if (this.$store.state.user?.isLoggedin) {
          this.user = this.$store.state.user
          this.refreshLoad = true
          this.coinsLoaded = false
          this.teamValue = 0
          dbFunctions.readUsersProfile(this.user.uid)
            .then(profileData => {
              this.user.profile = profileData
              this.$store.dispatch('getUserProfile', this.user)
              this.mySilverCoins = profileData.silverKombatCoins || 0
              this.myGoldCoins = profileData.goldKombatCoins || 0
              this.myPoints = profileData.totalPoints || 0
              if (this.user.isLoggedin) {
                this.getFighters()
              }
            })
          db.collection('profiles')
            .doc(this.user.uid)
            .onSnapshot(doc => {
              this.user.profile = doc.data()
              this.mySilverCoins = this.user.profile.silverKombatCoins || 0
              this.myGoldCoins = this.user.profile.goldKombatCoins || 0
              this.$store.dispatch('getUserProfile')
              this.refreshLoad = false
              this.coinsLoaded = true
            })
        } else {
          this.refreshLoad = false
          this.coinsLoaded = true
        }
        if (window.$crisp) {
          window.$crisp?.push(['safe', true], ['set', 'user:email', [this.user.email]])
          window.$crisp?.push(['safe', true], ['set', 'user:nickname', [this.user.displayName]])
        }
      }
    },
    async getFighters() {
      this.teamValue = 0
      await dbFunctions.getAllTeamFighters(this.user.uid)
        .then(querySnapshot => {
          this.teamValue = 0
          querySnapshot.forEach(doc => {
            this.teamValue += doc.data().cost
          })
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
}
</script>

<style scoped>
.mobile .site-status {
  height: 20;
  background: #312d4b;
  z-index: 9;
  padding: 5px 10px;
  margin-top: 3px;
  margin-bottom: 15px;
  font-size: 12px;
}

button.v-app-bar__nav-icon.v-btn.v-btn--fixed {
  position: fixed;
  z-index: 6;
  margin: 8px;
  border: 2px solid;
  opacity: 0.7;
}

div#game-menu {
  display: flex;
}

.desktop .backgroundOctagon {
  background-color: #312D4B !important;
  background: url('~@/assets/images/misc/purplecomb.webp');
  background-repeat: no-repeat;
  background-size: auto;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-attachment: fixed;
}

.mobile .backgroundOctagon {
  background-color: #222 !important;
}

.mobile div#game-menu span.text--primary.text-base.me-1 {
  font-size: 10px !important;
  line-height: 8px !important;
}

div#game-menu span.text--primary.text-base.me-1 {
  font-size: 12px !important;
  line-height: 9px !important;
}

.mobile div#game-menu .v-list-item__subtitle.text-xs {
  font-size: 9px !important;
  line-height: 7px !important;
  margin: 0 0 9px 0;
}

div#game-menu .v-list-item__subtitle.text-xs {
  font-size: 12px !important;
  line-height: 9px !important;
  margin: 0 0 5px 0;
}

.mobile div#game-menu .v-list-item .me-2 {
  margin-right: 5px !important;
}

div#game-menu .v-list-item {
  padding: 0 3px;
}

.v-app-bar ::v-deep {

.v-toolbar__content {
  padding: 0;
}

}

.boxed-container {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

.memberpricing-bg {
  position: relative;
}

.membership-pricing {
  text-align: center;

sup {
  font-size: 3.75rem;
  top: 9px;
}

}
</style>
