<template>
  <v-app>
    <v-main :class="user.isloggedin === 'true' ? 'backgroundDefault' : 'backgroundOctagon' ">
      <router-view></router-view>
    </v-main>

    <v-footer
      color="transparent"
      class="pa-0"
    >
      <div class="w-full mb-4 mt-4 mr-2">
        <div class="mx-2 d-flex justify-end">
          <span class="mr-2">
            <a
              href="https://support.fantasykombat.com/"
              target="_blank"
              class="text-decoration-none text-xs"
            >Support</a>
          </span>
          <span class="mr-2">
            <a
              href="https://support.fantasykombat.com/en/article/terms-of-service-qhnr7c/"
              target="_blank"
              class="text-decoration-none text-xs"
            >Terms of Service</a>
          </span>
          <span clsss="mr-2">
            <a
              href="https://support.fantasykombat.com/en/article/privacy-policy-hlxqnr/"
              target="_blank"
              class="text-decoration-none text-xs"
            >Privacy Policy</a>
          </span>
          <span>
            <a
              href="https://twitter.com/Fantasy_Kombat"
              target="_blank"
              class="text-decoration-none text-xs ml-2"
            ><v-icon>{{ icons.mdiTwitter }}</v-icon></a>
          </span>
          <span>
            <a
              href="https://www.instagram.com/fantasykombat/"
              target="_blank"
              class="text-decoration-none text-xs ml-2"
            ><v-icon>{{ icons.mdiInstagram }}</v-icon></a>
          </span>
          <span class="d-sm-inline d-none copyright mr-2 ml-2 mt-1 text-sm">
            &copy; {{ new Date().getFullYear() }} <a
            href="https://www.FantasyKombat.com"
            class="text-decoration-none "
            target="_blank"
          >FantasyKombat</a>
          </span>
        </div>
      </div>
    </v-footer>
  </v-app>
</template>

<script>
import {
  mdiTwitter,
  mdiInstagram,
} from '@mdi/js'

export default {
  setup() {
    const user = {
      name: '',
      email: '',
      password: '',
      isLoggedin: false,
    }

    return {
      user,
      icons: {
        mdiTwitter,
        mdiInstagram,
      },
    }
  },
}
</script>

<style scoped>
.backgroundOctagon {
  background-color: #312D4B !important;
  background: url('~@/assets/images/misc/purplecomb.webp');
  background-repeat: no-repeat;
  background-size: auto;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-attachment: fixed;
}

.backgroundOctagon {
  background-color: #222 !important;
}

</style>
