import Vue from 'vue'
import phrases from '@/data/dialogue.json'

// eslint-disable-next-line
const {hired, fired, won, loss, bonus} = phrases

Vue.filter('toCurrency', (value => parseFloat(value).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
))

Vue.filter('toCurrencyNoDec', (value => parseFloat(value).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
))

Vue.filter('silvertoGold', (value => {
  const xrate = 0.000055 // also change in firebase
  const gold = value * xrate

  return gold.toFixed(2)
}))

Vue.filter('oneDecimal', (value => {
  if (typeof value !== 'number') {
    return value
  }

  return value.toFixed(1)
}))

Vue.filter('twoDecimal', (value => {
  if (typeof value !== 'number') {
    return value
  }

  return value.toFixed(2)
}))

Vue.filter('fourDecimal', (value => {
  if (typeof value !== 'number') {
    return value
  }

  return value.toFixed(4)
}))

Vue.filter('isoToCountry', (value => {
  if (typeof value !== 'string') {
    return value
  }
  let country = value
  const countryName = new Intl.DisplayNames(['en'], {type: 'region'})
  try {
    country = countryName.of(value.toUpperCase())
  } catch (err) {
    console.log(err)
  }

  return country
}))

Vue.filter('hiredPhraseGen', ((value, dtype) => {
  if (!value) {
    // eslint-disable-next-line no-eval
    const phraseType = eval(dtype)
    const phrase = phraseType[Math.floor(Math.random() * phraseType.length)]

    return phrase
  }

  return value
}))

Vue.filter('attrColor', (value => {
  if (value === 'all') {
    return 'success'
  }
  if (value === 'recovery') {
    return 'pink'
  }
  if (value === 'health') {
    return 'red'
  }
  if (value === 'arcade') {
    return 'blue'
  }
  if (value === 'boxing') {
    return 'blue-grey'
  }
  if (value === 'strength') {
    return 'purple'
  }
  if (value === 'sponsorship') {
    return 'dark-green'
  }
  if (value === 'stamina') {
    return 'yellow'
  }
  if (value === 'speed') {
    return 'cyan'
  }
  if (value === 'kicks') {
    return 'orange'
  }
  if (value === 'wrestling') {
    return 'brown'
  }

  return 'secondary'
}))

Vue.filter('decimalToPercent', (value => {
  if (value === 0) {
    return 0
  }
  if (typeof value === 'number') {
    return (value * 100).toFixed(0)
  }

  return parseInt(value.replace('%', ''), 10).toFixed(1)
}))

Vue.filter('defaultDash', (value => {
  if (typeof value === 'undefined' || !value) {
    return '-'
  }

  return value
}))
Vue.filter('chartOptions', (value => ({
  chart: {
    height: 70,
    width: 90,
    type: 'line',
    zoom: {
      enabled: false,
    },
    toolbar: {
      show: false,
    },
  },
  tooltip: {
    enabled: false,
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: 'smooth',
    width: 2,
  },
  yaxis: {
    labels: {
      show: false,
    },
  },
  annotations: {
    yaxis: [
      {
        y: value.purchase_price || null,
        strokeDashArray: 2,
        color: '#56ca00',
      },
    ],
  },
  xaxis: {
    labels: {
      show: false,
    },
  },
})))

Vue.filter('chartSeries', (value => {
  if (typeof value?.price_history === 'undefined' || !value?.price_history) {
    let perc = 0
    if (value.last_change) {
      perc = value.last_change
    }
    const start = value.current_price - (value.current_price * perc)

    return [{data: [start, start, value.current_price]}]
  }

  return [{data: Object.values(value.price_history)}]
}))

Vue.filter('teamChartSeries', (value => {
  if (typeof value?.price_history === 'undefined' || !value?.price_history) {
    return [{data: [value.purchase_price, value.purchase_price * 1.1, value.current_price]}]
  }

  return [{data: Object.values(value.price_history)}]
}))

Vue.filter('lastChangePer', (value => {
  const varray = value[0].data
  const res = parseInt(((varray[varray.length - 1] - varray[varray.length - 2]) / varray[varray.length - 1]) * 100, 10).toFixed(0)

  return res
}))

Vue.filter('totalChangePer', (value => {
  const varray = value[0].data
  const res = parseInt(((varray[varray.length - 1] - varray[0]) / varray[varray.length - 1]) * 100, 10).toFixed(0)

  return res
}))
