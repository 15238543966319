import AES from 'crypto-js/sha256'
import encode from 'crypto-js/enc-utf8'
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'
import axios from 'axios'
import * as fcl from '@onflow/fcl'
import * as t from '@onflow/types'
import db from './init'

const _ = require('lodash')

export default {
  encryptData(value) {
    const hashKey = process.env.VUE_APP_HASH_secret
    const encryptData = AES.encrypt(
      value,
      hashKey,
    ).toString()

    return encryptData
  },
  decryptData(value) {
    const hashKey = process.env.VUE_APP_HASH_secret
    const decryptData = AES.decrypt(
      value,
      hashKey,
    ).toString(encode.Utf8)

    return decryptData
  },
  fileUpload(dir, filename, file) {
    const storage = firebase.storage().ref()
    const storageRef = storage.child(`${dir}/${filename}`)
    const task = storageRef.put(file)

    // update progress bar
    task.on('state_changed',
      snapshot => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        console.log(`Upload is ${progress} % done`)
        // eslint-disable-next-line default-case
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED:
            console.log('Upload is paused')
            break
          case firebase.storage.TaskState.RUNNING:
            console.log('Upload is running')
            break
          default:
        }
      },
      error => {
        console.log(error)
      },
      () => {
        // Handle successful uploads on complete
        task.snapshot.ref.getDownloadURL().then(downloadURL => {
          console.log('File available at', downloadURL)

          return downloadURL
        })
      })
  },
  createProfile(userUID) {
    console.log('createProfile')
    if (userUID !== '') {
      db.collection('profiles').doc(userUID)
        .set({
          userUID,
          lastLogin: firebase.firestore.FieldValue.serverTimestamp(),
          sessions: firebase.firestore.FieldValue.increment(1), // Number of logins
          rank: 0, // Belt
          xp: 0, // Experience points
          totalPoints: 0, // Fantasy Points
          lastPoints: 0, // Fantasy Points
          eventsEntered: 0, // tournament events
          arcadePlayed: 0, // total arcade games played
          achievements: [], // Torno won, unlocked
          onBoardered: false, // issue one random player and 1M KombatCoins
          starDustPlayerId: '',
          silverKombatCoins: 0, // Stored total refer to SD for live transactions
          silverKombatCoinsLastTrans: 0, // Last Transaction
          freeSilverKombatCoins: 0, // number of free kombatCoins issued
          goldKombatCoins: 0, // Stored total refer to SD for live transactions
          goldKombatCoinsLastTrans: 0, // Last Transaction
          solanaAddress: '', // Solana internal game Wallet Address for SPL
          teams: [], // Fantasy Teams
          freeFighterPacks: 0, // number of random fighter packs issued
          accountType: 0, // Account type 0 free, 1 paid
          country: 0, // User origin  0 free, 1 paid
          locale: '', // used for timezone and formatting
          age: 0, // Birthdate
          bio: '', // User free text profile bio
        })
        .then(() => {
          console.log('Profile successfully created!')
        })
        .catch(error => {
          console.error('Error writing profile: ', error)
        })
    }
  },
  async readUsersProfile(userUID) {
    let profileData = {}
    await db.collection('profiles')
      .doc(userUID)
      .get()
      .then(doc => {
        profileData = doc.data()
      })
      .catch(error => {
        console.log('Error getting documents: ', error)
      })

    return profileData
  },
  async updateUsersProfile(user, fields) {
    const profileData = fields
    profileData.modified = firebase.firestore.FieldValue.serverTimestamp()
    await db.collection('profiles')
      .doc(user.uid)
      .update(profileData)
      .then(profile => profile)
      .catch(error => {
        console.log(error)
      })
  },
  async createTeam(userUID) {
    if (userUID !== '') {
      await db.collection('teams').doc(userUID)
        .set({
          owner: userUID,
          created: firebase.firestore.FieldValue.serverTimestamp(),
          name: '',
          desc: '',
          logo: '', // Image URL
          color: '', // Team primary color
          captain: '', // Fighter allocated as captain
          advisor: [], // Team Advisor
          training: [], // Team Advisor
          leagues: [], // Participating leagues
          totalPoints: 0, // Fantasy Points to compare
          lastPoints: 0, // Fantasy Points
          teamValue: 0, // Total value of team based on all teams fighters
          activeFighters: [], // Fighters in Team
          scoutedFighters: [], // Fighters added from scouting
          inactiveFighters: [], // Hold for in active Fighters in Team
          followedFighters: [], // Fighters user is following
        })
        .then(() => {
          console.log('Team successfully created')

          return true
        })
        .catch(error => {
          console.error('Error creating team: ', error)

          return false
        })
    }
  },
  async getTeam(userUID) {
    let teamData = {}
    if (userUID !== '') {
      await db.collection('teams').doc(userUID)
        .get()
        .then(doc => {
          teamData = doc.data()
          if (teamData) {
            teamData.id = doc.id
          }
        })
        .catch(error => {
          console.log('Error getting team: ', error)
        })
    }

    return teamData
  },
  async updateTeam(userUID, fields) {
    const teamData = fields
    if (userUID !== '') {
      teamData.modified = firebase.firestore.FieldValue.serverTimestamp()
      await db.collection('teams')
        .doc(userUID)
        .update(teamData)
        .then(() => {
          console.log('Team updated')
        })
        .catch(error => {
          console.log(error)
        })
    } else {
      this.$router.push('/403')
    }
  },
  async getAdvisors() {
    return db.collection('advisors')
      .get()
      .then(querySnapshot => querySnapshot)
      .catch(error => {
        console.log('Error getting documents: ', error)
      })
  },
  async getAllFighters(filters) {
    let qfilters = filters
    if (!qfilters) {
      qfilters = ['pending-update', 'active']
    }

    return db.collection('fighters')
      .where('status', 'in', qfilters)
      .get()
      .then(querySnapshot => querySnapshot)
      .catch(error => {
        console.log('Error getting documents: ', error)
      })
  },
  async getRandomFighter() {
    return db.collection('fighters')
      .where('rank', '=', 0)
      .get()
      .then(querySnapshot => {
        const array = querySnapshot.docs
        const fighter = array[Math.floor(Math.random() * array.length)]

        return (fighter.data().fighter_id)
      })
      .catch(error => {
        console.log('Error getting documents: ', error)
      })
  },
  async getRandomRankedFighter() {
    return db.collection('fighters')
      .where('rank', '>', 1)

      .get()
      .then(querySnapshot => {
        const fighterData = []
        let fighter = {}
        querySnapshot.forEach(doc => {
          const data = doc.data()
          if (!data.xfactor) {
            data.id = doc.id
            fighterData.push(data)
          }
        })

        fighter = fighterData[Math.floor(Math.random() * fighterData.length)]

        return (fighter.fighter_id)
      })
      .catch(error => {
        console.log('Error getting documents: ', error)
      })
  },
  async getAllTeamFighters(userUID) {
    return db.collection('mintedFighters')
      .where('current_team', '==', userUID)
      .get()
      .then(querySnapshot => querySnapshot)
      .catch(error => {
        console.log('Error getting documents: ', error)
      })
  },
  async syncFighterBaseMintedTemplate(fighterData) {
    console.log('sync DB functions')
    console.log(fighterData)
    const fdata = fighterData
    db.collection('mintedFighters')
      .where('token_template_id', '==', fdata.token_template_id)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          const data = doc.data()
          data.id = doc.id
          if (doc.id) {
            if (fdata.status === 'pending-update') {
              fdata.status = 'active'
            }
            console.log(fdata.status)
            this.updateMintedFighter(doc.id, fdata)
          }
        })
      })
      .catch(error => {
        console.log('Error getting documents: ', error)
      })
  },
  async createLeague(userUID, fields) {
    return db.collection('leagues')
      .add({
        createdBy: userUID,
        admins: [userUID], // league admin user ideas
        created: firebase.firestore.FieldValue.serverTimestamp(),
        maxPlayers: 100,
        name: fields.name,
        desc: '',
        status: 'active',
        logo: fields.logo, // Image URL
        visibility: fields.visibility, // private leagues are invite only
        inviteCode: Math.random().toString(16).slice(8), // Code to share to others to join
        totalPoints: 0, // Fantasy Points to compare
        lastPoints: 0, // Fantasy Points
        leagueValue: 0, // value of league based on all teams fighters
        achievements: [], // League Achievements
        leagueType: 0, // League Account type 0 default
        partner: fields?.partner || null, // verified partner
        embed1: fields?.embed1 || null, // youtube
        embed2: fields?.embed1 || null, // spotify other
        twitter: fields?.twitter || null, // twitter handler
      })
      .then(data => data)
      .catch(error => {
        console.error('Error writing league: ', error)
      })
  },
  async updateLeague(userUID, LeagueID, fields) {
    if (userUID === fields.createdBy) {
      const leagueData = fields
      leagueData.modified = firebase.firestore.FieldValue.serverTimestamp()
      await db.collection('leagues')
        .doc(LeagueID)
        .update(leagueData)
        .then(() => {
          console.log('League updated')
        })
        .catch(error => {
          console.log(error)
        })
    } else {
      console.log('Only owners can edit leagues')
    }
  },

  async removefromLeague(leagueId, userId) {
    await this.getTeam(userId).then(async teamData => {
      teamData.leagues.splice(teamData.leagues.indexOf(leagueId), 1)
      const fields = {leagues: teamData.leagues}
      await this.updateTeam(userId, fields)
    })

    return 'done'
  },
  async getLeagueTeams(leagueId) {
    return db.collection('teams')
      .where('leagues', 'array-contains', leagueId)
      .get()
      .then(querySnapshot => {
        const teams = []
        querySnapshot.forEach(doc => {
          const team = doc.data()
          team.id = doc.id
          teams.push(team)
        })

        return teams
      })
      .catch(error => {
        console.log('Error getting leagues: ', error)
      })
  },
  async getMyLeagues(leagueId) {
    return db.collection('leagues')
      .doc(leagueId)
      .get()
      .then(doc => doc)
      .catch(error => {
        console.log('Error getting leagues: ', error)
      })
  },
  async getLeagues(status) {
    return db.collection('leagues')
      .where('status', '==', status)
      .get()
      .then(querySnapshot => {
        const leagues = []
        querySnapshot.forEach(doc => {
          const league = doc.data()
          league.id = doc.id
          leagues.push(league)
        })

        return leagues
      })
      .catch(error => {
        console.log('Error getting leagues: ', error)
      })
  },
  async getBaseFighter(fighterId) {
    let fighterData = {}
    await db.collection('fighters')
      .where('fighter_id', '==', fighterId)
      .get()
      .then(querySnapshot => {
        const fighter = []
        querySnapshot.forEach(doc => {
          const data = doc.data()
          data.id = doc.id
          fighter.push(data)
        })
        fighterData = fighter[0]  // eslint-disable-line

        return fighterData
      })
      .catch(error => {
        console.log('Error getting fighter data: ', error)
      })

    return fighterData
  },

  async getFighterFromName(fighterName) {
    let fighterData = {}
    await db.collection('fighters')
      .where('name', '==', fighterName)
      .get()
      .then(querySnapshot => {
        const fighter = []
        querySnapshot.forEach(doc => {
          const data = doc.data()
          data.id = doc.id
          fighter.push(data)
        })
        fighterData = fighter[0]  // eslint-disable-line

        return fighterData
      })
      .catch(error => {
        console.log('Error getting fighter data: ', error)
      })

    return fighterData
  },
  async getBaseFighterSlug(fighterSlug) {
    let fighterData = {}
    const fighter = []
    await db.collection('fighters')
      .where('slug', '==', fighterSlug)
      .get()
      .then(async querySnapshot => {
        querySnapshot.forEach(doc => {
          const data = doc.data()
          data.id = doc.id
          fighter.push(data)
        })
        fighterData = fighter[0]  // eslint-disable-line
        if (!fighterData) {
          await db.collection('fighters')
            .where('ufc_slug', '==', fighterSlug)
            .get()
            .then(querySnapshot1 => {
              querySnapshot1.forEach(doc => {
                const data = doc.data()
                data.id = doc.id
                fighter.push(data)
              })
              // eslint-disable-next-line prefer-destructuring
              fighterData = fighter[0]
            })
        }

        return fighterData
      })
      .catch(error => {
        console.log('Error getting fighter data: ', error)
      })

    return fighterData
  },
  async updateImage(fighterData) {
    const api = axios.create({
      baseURL: `${process.env.VUE_APP_VM_BASE}/`,
      timeout: 10 * 60 * 1000, // whatever time you want
    })
    if (fighterData.head_shot_url) {
      api.get(`?func=toonify&image=${encodeURIComponent(fighterData.head_shot_url)}&slug=${fighterData.slug}&shot=head`).then(() => {
        console.log('Process image')
      })
    } else {
      console.log('no head shot url')
    }
    if (fighterData.full_body_url) {
      api.get(`?func=toonify&image=${encodeURIComponent(fighterData.full_body_url)}&slug=${fighterData.slug}&shot=body`).then(() => {
        console.log('Process image')
      })
    } else {
      console.log('no body shot url')
    }
  },
  async updateMissingImages() {
    return db.collection('fighters')
      .where('full_body_url', '==', '')
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          const fdata = doc.data()
          fdata.status = 'active'
          console.log(fdata.slug)
          this.updateImage(fdata)
        })
      })
      .catch(error => {
        console.log('Error getting documents: ', error)
      })
  },
  async getMintedFighter(UID) {
    let fighterData = {}
    await db.collection('mintedFighters')
      .doc(UID)
      .get()
      .then(doc => {
        fighterData = doc.data()
      })
      .catch(error => {
        console.log('Error getting fighter data: ', error)
      })

    return fighterData
  },
  async getAllMintedFighters() {
    return db.collection('mintedFighters')
      .get()
      .then(querySnapshot => querySnapshot)
      .catch(error => {
        console.log('Error getting fighter data: ', error)
      })
  },
  async saveMintedFighter(fighter, ownerUid) {
    console.log('Start save minted Fighter')
    const fighterProps = fighter
    fighterProps.owner = ownerUid
    fighterProps.current_team = ownerUid
    fighterProps.purchase_price = fighter.purchase_price || fighter.current_price
    fighterProps.current_price = fighter.current_price || 0
    fighterProps.created = firebase.firestore.FieldValue.serverTimestamp()
    await db.collection('mintedFighters')
      .add(fighterProps)
      .then(fighterRecord => {
        console.log('Minted Fighter successfully saved')
        this.NFTstatus = 'NFT process complete!'

        return fighterRecord.id
      })
      .catch(error => {
        console.error('Error writing minted fighter: ', error)
      })
  },
  async updateFighter(slug, fields) {
    await db.collection('fighters')
      .doc(slug)
      .update(fields)
      .then(fighterData => fighterData)
      .catch(error => {
        console.log(error)

        return error
      })
  },
  async updateMintedFighter(uid, fighterFields) {
    console.log('update minted fighter attributes')
    const fields = fighterFields
    await db.collection('mintedFighters')
      .doc(uid)
      .get()
      .then(doc => {
        const fighterData = doc.data()
        fields.last_updated = firebase.firestore.FieldValue.serverTimestamp()
        fields.current_price = fighterFields?.current_price || fighterData.current_price
        fields.purchase_price = fighterData.purchase_price || fields.current_price
        fields.cost = fighterData.cost || fields.cost

        if (fields.training) {
          delete fields.training
        }
        if (fighterFields.head_shot_url) {
          const fbimage = `https://storage.googleapis.com/fk-public/body-${fighterFields.slug}.png`
          const himage = `https://storage.googleapis.com/fk-public/head-${fighterFields.slug}.png`
          fields.head_shot_url = himage
          fields.full_body_url = fbimage
          fields.image = fbimage
        }
        db.collection('mintedFighters')
          .doc(uid)
          .update(fields)
          .then(data => data)
          .catch(error => {
            console.log(error)

            return error
          })
      })
      .catch(error => {
        console.log(error)

        return error
      })
  },
  async checkAdmin(user) {
    await this.readUsersProfile(user.uid)
      .then(data => {
        if (data.admin) {
          return true
        }

        return false
      })
      .catch(error => {
        console.log(error)

        return false
      })
  },
  async getUFCEvent(id) {
    let eventData = []
    await db.collection('ufcEvents')
      .doc(id)
      .get()
      .then(eventResp => {
        eventData = eventResp.data()
      })
      .catch(error => {
        console.log('Error getting eventData: ', error)
      })

    return eventData
  },
  async searchUFCEvent(searchField, id) {
    const eventData = []
    await db.collection('ufcEvents')
      .where(searchField, '==', id)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          const data = doc.data()
          data.id = doc.id
          eventData.push(data)
        })
      })
      .catch(error => {
        console.log('Error getting eventData: ', error)
      })

    return eventData
  },
  async allUFCEvents(past = false, limit = 100) {
    const tomorrow = new Date()
    tomorrow.setDate(tomorrow.getDate() - 0.5)
    const eventData = []
    await db.collection('ufcEvents')
      .limit(limit)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          const data = doc.data()
          data.id = doc.id
          data.event_type = 'MMA Fight'
          data.prize_pool = '1750000' // default prize pool
          data.link = `/tournaments/ufc-fight-night/${data.id}`

          if (!data.date) {
            data.date = new Date(data.id)
          }
          data.timestamp = new Date(data.date).getTime()
          if (Date.parse(data.date)) {
            if (past) {
              if (new Date(data.date) <= tomorrow) {
                if (!data.status) {
                  data.status = 'past'
                }
                if (!data.hide) {
                  eventData.push(data)
                }
              }
            } else if (new Date(data.date) > tomorrow) {
              if (!data.status) {
                data.status = 'upcoming'
              }
              if (!data.hide) {
                eventData.push(data)
              }
            }
          }
        })
      })
      .catch(error => {
        console.log('Error getting eventData: ', error)
      })

    return eventData
  },
  async nextUFCeventFighters() {
    const tomorrow = new Date()
    tomorrow.setDate(tomorrow.getDate() - 0.5)
    const eventData = []
    await db.collection('ufcEvents')
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          const data = doc.data()
          data.id = doc.id
          if (!data.date) {
            data.date = new Date(data.id)
          }
          data.timestamp = new Date(data.date).getTime()
          if (Date.parse(data.date)) {
            if (new Date(data.date) <= tomorrow) {
              if (!data.status) {
                data.status = 'past'
              }
              eventData.push(data)
            } else if (new Date(data.date) > tomorrow) {
              if (!data.status) {
                data.status = 'upcoming'
              }
              eventData.push(data)
            }
          }
        })
      })
      .catch(error => {
        console.log('Error getting eventData: ', error)
      })

    return eventData
  },
  async updateUFCEvent(id, ufields) {
    const fields = ufields

    // move first item to last
    const moveFirstLast = false
    if (moveFirstLast) {
      fields.fights.push(fields.fights.shift())
    }
    console.log('updateevents')
    console.log(fields.fights)
    fields.modified = firebase.firestore.FieldValue.serverTimestamp()
    await db.collection('ufcEvents')
      .doc(id)
      .update(fields)
      .then(data => {
        console.log(data)
      })
      .catch(error => {
        console.log('Error getting eventData: ', error)
      })
  },
  async getCurrencies() {
    return db.collection('currencies')
      .get()
      .then(querySnapshot => {
        const currencyList = []
        querySnapshot.forEach(doc => {
          const data = doc.data()
          currencyList.push(data)
        })

        return currencyList
      }).catch(error => {
        console.log('Error getting currency data: ', error)
      })
  },
  async checkBalance(user, currencyField, cost) {
    let profileData = {}
    const balanceData = {
      balance: 0,
      sufficientBalance: false,
    }
    await db.collection('profiles')
      .doc(user.uid)
      .get()
      .then(doc => {
        profileData = doc.data()

        // console.log('db balance check')
        balanceData.balance = profileData[currencyField] || 0
        if (cost > balanceData.balance) {
          balanceData.sufficientBalance = true
        }
      })
      .catch(error => {
        console.log('Error getting documents: ', error)
      })

    return balanceData
  },
  async completeCoinTransaction(user, currencyField, balance, cost) {
    const afterDeduct = balance - cost
    const fields = {}
    fields.modified = firebase.firestore.FieldValue.serverTimestamp()
    fields[currencyField] = afterDeduct
    await db.collection('profiles')
      .doc(user.uid)
      .update(fields)
      .then(profile => profile)
      .catch(error => {
        console.log(error)
      })
  },
  async savePickem(userUID, eventID, picks, date, status) {
    const userEventId = `${userUID}-${eventID}`
    let saved = false
    if (userUID !== '') {
      const data = []
      data.owner = userUID
      data.eventID = eventID
      if (status === 'Upcoming') {
        const modified = firebase.firestore.FieldValue.serverTimestamp()
        await db.collection('ufcPickEms').doc(userEventId)
          .set({
            data: picks,
            status: 'next',
            modified,
            owner: data.owner,
            eventID: data.eventID,
          })
          .then(() => {
            saved = false
          })
          .catch(error => {
            console.error('Error creating team: ', error)
            saved = false
          })
      } else if (status === 'Live') {
        console.log('Event already started')
        const modified = firebase.firestore.FieldValue.serverTimestamp()
        await db.collection('ufcPickEms').doc(userEventId)
          .set({
            status: 'live',
            modified,
            owner: data.owner,
            eventID: data.eventID,
          })
          .then(() => {
            saved = false
          })
          .catch(error => {
            console.error('Error creating team: ', error)
            saved = false
          })
        saved = false
      } else {
        saved = false
      }
    }

    return saved
  },
  async getPicks(userUID, eventID) {
    const userEventId = `${userUID}-${eventID}`

    return db.collection('ufcPickEms')
      .doc(userEventId)
      .get()
      .then(doc => doc.data())
      .catch(error => {
        console.log('Error getting data: ', error)
      })
  },
  async getAllPicks() {
    const picks = []
    await db.collection('ufcPickEms')
      .get()
      .then(querySnapshot => {
        let data = {}
        querySnapshot.forEach(doc => {
          data = doc.data()
          data.id = doc.id
          picks.push(data)
        })
      })

    return picks
  },
  sendPasswordReset(email) {
    firebase.auth().sendPasswordResetEmail(email)
      .then(() => {
        console.log('Password reset email sent')
      })
      .catch(error => {
        console.log(error)
      })
  },

  async addCreatePowerUps(userUID, dapperAccount, powerups, create) {
    let pudata = {}
    if (userUID !== '') {
      const fields = powerups
      fields.owner = userUID
      fields.dapperAccount = dapperAccount
      fields.modified = firebase.firestore.FieldValue.serverTimestamp()
      if (create) {
        fields.created = firebase.firestore.FieldValue.serverTimestamp()
      }
      await db.collection('ufcStrikePowerUps').doc(userUID)
        .set(fields, {merge: true})
        .then(loadedpudata => {
          pudata = loadedpudata
        })
        .catch(error => {
          console.error('Error creating powerups: ', error)

          return false
        })
    }

    return pudata
  },

  async resetPowerUps(userUID) {
    let pudata = {}
    if (userUID !== '') {
      const fields = {}
      fields.owner = userUID
      fields.powerUps = []
      fields.powerUpsUsed = []
      fields.modified = firebase.firestore.FieldValue.serverTimestamp()
      await db.collection('ufcStrikePowerUps').doc(userUID)
        .set(fields, {merge: true})
        .then(loadedpudata => {
          pudata = loadedpudata
        })
        .catch(error => {
          console.error('Error creating powerups: ', error)

          return false
        })
    }

    return pudata
  },
  async getPowerUps(userUID) {
    let fields = {}
    if (userUID !== '') {
      await db.collection('ufcStrikePowerUps').doc(userUID)
        .get()
        .then(doc => {
          fields = doc.data()
          if (fields) {
            fields.id = doc.id
          }
        })
        .catch(error => {
          console.log('Error getting PowerUps: ', error)
        })
    } else {
      this.$router.push('/403')
    }

    return fields
  },
  async getPrizeList(uid, claimed) {
    const prizeClaims = []
    await db.collection('prizeClaims')
      .where('userUid', '==', uid)
      .where('claimed', '==', claimed)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          const prize = doc.data()
          prize.id = doc.id
          prizeClaims.push(prize)
        })

        return prizeClaims
      })
      .catch(error => {
        console.log('Error getting claim data: ', error)
      })

    return prizeClaims
  },
  async prizeClaimed(id, cfields, claimed) {
    const prizeClaims = []
    const fields = cfields
    if (claimed) {
      fields.claimedDate = firebase.firestore.FieldValue.serverTimestamp()
      fields.claimed = claimed
    } else {
      fields.created = firebase.firestore.FieldValue.serverTimestamp()
      fields.claimed = false
    }
    await db.collection('prizeClaims')
      .doc(id)
      .update(fields)
      .catch(error => {
        console.log('Error saving claim: ', error)
      })

    return prizeClaims
  },
  async getAllUserProfile() {
    return db.collection('profiles')
      .where('accountType', '>=', 0)
      .get()
      .then(querySnapshot => querySnapshot)
      .catch(error => {
        console.log('Error getting users: ', error)
      })
  },
  async getClaims() {
    const prizeClaims = []
    await db.collection('prizeClaims')
      .get()
      .then(querySnapshot => {
        let data = {}
        querySnapshot.forEach(doc => {
          data = doc.data()
          data.id = doc.id
          prizeClaims.push(data)
        })
      })

    return prizeClaims
  },
  async addClaim(userUid, claimType, event, desc, silverCoins, goldCoins, rewardType, from) {
    const created = firebase.firestore.FieldValue.serverTimestamp()
    await db.collection('prizeClaims')
      .add({
        userUid,
        claimType,
        event,
        desc,
        silverCoins,
        goldCoins,
        rewardType,
        created,
        from,
        claimed: false,
      })
      .then(() => {
        console.log('claim added')
      })
      .catch(error => {
        console.error('Error creating team: ', error)
      })
  },
  async deleteClaim(id) {
    await db.collection('prizeClaims')
      .doc(id)
      .delete()

    return 'deleted'
  },

  // todo move to cloud function
  async saveLeaguePoints(ID, last, total) {
    const leagueData = {}
    leagueData.modified = firebase.firestore.FieldValue.serverTimestamp()
    leagueData.lastPoints = last
    leagueData.totalPoints = total
    await db.collection('leagues')
      .doc(ID)
      .update(leagueData)
      .then(() => {
        console.log('League updated')
      })
      .catch(error => {
        console.log(error)
      })
  },

  // todo move to cloud function
  async setUFCEventStatusBlockTrading(fights) {
    const fighters = []
    for (let i = 0; i < fights.length; i += 1) {
      fighters.push(fights[i].fighter_1_id)
      fighters.push(fights[i].fighter_2_id)
    }
    for (let i = 0; i < fighters.length; i += 1) {
      db.collection('fighters')
        .where('fighter_id', '==', fighters[i])
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            console.log(doc.id)
            db.collection('fighters')
              .doc(doc.id)
              .update({status: 'pending-update'})
              .catch(error => {
                console.log(error)
              })
          })
        })
    }
  },
  async setPendingActive() {
    console.log('check pending')

    return db.collection('fighters')
      .where('fighter_trading_blocked', '==', true)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          const fdata = doc.data()
          fdata.fighter_trading_blocked = false
          console.log(doc.id)
          this.updateFighter(doc.id, fdata)
        })
      })
      .catch(error => {
        console.log('Error getting documents: ', error)
      })
  },

  // todo move to cloud function
  async updateAllMintedFighters() {
    return db.collection('mintedFighters')
      .where('status', '==', 'active')
      .get()
      .then(querySnapshot => {
        console.log(querySnapshot)
        querySnapshot.forEach(doc => {
          const data = doc.data()
          data.id = doc.id
          if (data.purchase_price === 0) {
            data.purchase_price = data.cost
          }
          if (data.current_price === 0) {
            data.current_price = data.cost
          }
          console.log(data)
          console.log(data.slug)
          const fid = this.getBaseFighter(data.id)
          console.log(fid)
          db.collection('mintedFighters')
            .doc(data.id)
            .update(data)
            .catch(error => {
              console.log(error)

              return error
            })
        })
      })
      .catch(error => {
        console.log('Error getting documents: ', error)
      })
  },

  async updateStrikeNfts(userUid, dapperAddress, dapperAddressCreated, allFighters) {
    fcl.config({
      'discovery.wallet': 'https://accounts.meetdapper.com/fcl/authn-restricted',
      'discovery.wallet.method': 'POP/RPC',
      'accessNode.api': 'https://access-mainnet-beta.onflow.org',
      'app.detail.title': 'FantasyKombat',
      'app.detail.icon': 'https://www.fantasykombat.com/logo_small.png',
    })

    // get owned NFT metadata
    const idsResponse = await fcl.send([
      fcl.script`
          import UFC_NFT from 0x329feb3ab062d289
          pub struct MetadataCollections {

            pub let items: {String : MetadataCollectionItem}
            pub let collections: {String : [String]}

            init(items: {String : MetadataCollectionItem}, collections: {String : [String]}) {
              self.items=items
              self.collections=collections
              }
          }

          pub struct MetadataCollection{
            pub let type: String
            pub let items: [MetadataCollectionItem]

            init(type:String, items: [MetadataCollectionItem]) {
                self.type=type
                self.items=items
                }
          }

          pub struct MetadataCollectionItem {
            pub let id:UInt64
            pub let name: String
            pub let image: String
            pub let url: String
            pub let listPrice: UFix64?
            pub let listToken: String?
            pub let mintQty: UInt32
            pub let setId: UInt32
            pub let seriesId: UInt32
            pub let metadata: {String: String}


            init(id:UInt64, name:String, image:String, url:String, listPrice: UFix64?, listToken:String?,  mintQty: UInt32, setId: UInt32, seriesId: UInt32, metadata: {String: String}) {
              self.id=id
              self.name=name
              self.url=url
              self.image=image
              self.listToken=listToken
              self.listPrice=listPrice
              self.mintQty=mintQty
              self.setId=setId
              self.seriesId=seriesId
              self.metadata=metadata
              }
            }

          pub fun main(account: Address): MetadataCollections? {
            let resultMap : {String : MetadataCollectionItem} = {}
            let results : {String :  [String]}={}
            let acct = getAccount(account)
            let collectionRef = acct.getCapability<&UFC_NFT.Collection{UFC_NFT.UFC_NFTCollectionPublic}>(UFC_NFT.CollectionPublicPath)
            let collection = collectionRef.borrow()!
            let items: [String] = []
            for id in collection.getIDs() {
                  let nft = collection.borrowUFC_NFT(id: id)!
                  let metadata = UFC_NFT.getSetMetadata(setId: nft.setId)!
                  let seriesId = UFC_NFT.getSetSeriesId(setId: nft.setId)!
                  let image = metadata["image"]!
                  let data = UFC_NFT.getSetMaxEditions(setId: nft.setId)!
                  let contentType="video"
                  let item = MetadataCollectionItem(
                      id: id,
                      name: metadata["name"]!,
                      image: image,
                      url: "https://www.ufcstrike.com",
                      listPrice: nil,
                      listToken: nil,
                      mintQty: UFC_NFT.getSetMaxEditions(setId: nft.setId)!,
                      setId: nft.setId,
                      seriesId: seriesId,
                      metadata: metadata
                      )

                  let itemId="UFC".concat(id.toString())
                  items.append(itemId)
                  resultMap[itemId] = item
            }

            if items.length != 0 {
              results["UFC"] = items
            }
            return MetadataCollections(items: resultMap, collections:results)
          }
        `,
      fcl.args([fcl.arg(dapperAddress, t.Address)]),
    ])
    const myuNFTs = await fcl.decode(idsResponse)
    let myNFTS = []
    for (let i = 0; i < Object.entries(myuNFTs.items).length; i += 1) {
      const item = Object.entries(myuNFTs.items)[i][1]
      if (item.metadata?.TIER) {
        let movType = item.metadata['HIGHLIGHT CATEGORY'] || item.metadata['HIGHLIGHT TYPE']
        if (movType.toLowerCase() === 'submission' || movType.toLowerCase() === 'sub') {
          movType = 'SUB'
        } else if (movType.toLowerCase() === 'grappling' || movType.toLowerCase() === 'striking') {
          movType = 'DEC'
        } else if (movType.toLowerCase() === 'ko/tko') {
          movType = 'KO/TKO'
        }
        let c = ''
        if (allFighters[item.metadata['ATHLETE NAME']]) {
          c = allFighters[item.metadata['ATHLETE NAME']]?.country
        }
        if (!allFighters[item.metadata['ATHLETE NAME']]) {
          console.log(`missing fighter ${item.metadata['ATHLETE NAME']}`)
        }
        if (!c) {
          console.log(`missing country ${item.metadata['ATHLETE NAME']}`)
        }

        myNFTS.push({
          index: i,
          name: item.metadata['ATHLETE NAME'],
          price: null,
          id: item.id,
          tier: item.metadata.TIER?.toLowerCase(),
          mov: movType,
          status: '',
          country: c,
        })
      }
    }
    myNFTS.sort((a, b) => a.name.localeCompare(b.name)) // order by name
    myNFTS = _.sortBy(myNFTS, 'tier') // put tier cards first
    let create = false
    if (!dapperAddressCreated) {
      create = true
    }
    let usedPowerUps = []
    let updatedPowerups = []
    if (dapperAddress !== '') {
      await db.collection('ufcStrikePowerUps').doc(userUid)
        .get()
        .then(async data => {
          usedPowerUps = data.data()?.powerUpsUsed || data.data()?.powerUps || []
          if (usedPowerUps) {
            const usedPowerUpsDict = Object.assign({}, ...usedPowerUps.map(x => ({[x.id]: x})))

            // if already used replace 'used' status
            myNFTS.forEach(item => {
              const moment = item
              if (usedPowerUpsDict[item.id]) {
                moment.status = usedPowerUpsDict[item.id].status
                updatedPowerups.push(moment)
              } else {
                updatedPowerups.push(moment)
              }
            })
          } else {
            updatedPowerups = myNFTS
          }
          const powerups = {powerUps: myNFTS, powerUpsUsed: updatedPowerups}
          const fields = powerups
          fields.owner = userUid
          fields.dapperAccount = dapperAddress
          fields.modified = firebase.firestore.FieldValue.serverTimestamp()
          if (create) {
            fields.created = firebase.firestore.FieldValue.serverTimestamp()
          }
          await db.collection('ufcStrikePowerUps').doc(userUid)
            .set(fields, {merge: true})
            .catch(error => {
              console.error('Error creating powerups: ', error)

              return false
            })

          return usedPowerUps
        })
        .catch(error => {
          console.error('Error creating powerups: ', error)

          return false
        })
    }
  },
}
