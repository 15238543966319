<template>
  <v-navigation-drawer
    :value="isDrawerOpen"
    app
    floating
    width="260"
    class="app-navigation-menu"
    :right="$vuetify.rtl"
    @input="val => $emit('update:is-drawer-open', val)"
  >
    <!-- Navigation Header -->
    <div class="vertical-nav-header d-flex items-center mx-auto text-center">
      <router-link
        to="/dashboard"
        class="d-flex align-center text-decoration-none"
      >
        <v-img
          :src="require('@/assets/images/logos/nav_logo.png')"
          max-height="110px"
          max-width="170px"
          alt="logo"
          contain
          eager
          class="app-logo d-md-block d-sm-block d-xs-block pb-1 mb-1 mt-2 pt-6 ml-7"
        ></v-img>
      </router-link>
    </div>

    <!-- Navigation Items -->
    <v-list
      expand
      shaped
      class="vertical-nav-menu-items pr-5 pt-0 mt-0"
    >
      <nav-menu-link
        title="Dashboard"
        :to="{ name: 'dashboard' }"
        :icon="icons.mdiHomeOutline"
      ></nav-menu-link>
      <nav-menu-link
        title="My Team"
        :to="{ name: 'my-team'}"
        :icon="icons.mdiAccountGroup"
      ></nav-menu-link>
      <nav-menu-link
        title="Tournaments"
        :to="{ name: 'fight-tournaments'}"
        :icon="icons.mdiTrophy"
      ></nav-menu-link>
      <nav-menu-link
        title="Leaderboards"
        :to="{ name: 'leaderboards'}"
        :icon="icons.mdiFormatListBulletedTriangle"
      ></nav-menu-link>
      <nav-menu-link
        title="Scout Fighters"
        :to="{ name: 'scout-fighters'}"
        :icon="icons.mdiAccountSearchOutline"
        class="d-none"
      ></nav-menu-link>
      <nav-menu-link
        title="Leagues"
        :to="{ name: 'leagues'}"
        :icon="icons.mdiTable"
      ></nav-menu-link>
      <nav-menu-link
        title="Recruit Fighters"
        :to="{ name: 'recruit-fighters'}"
        :icon="icons.mdiAccountCashOutline"
      ></nav-menu-link>
      <nav-menu-link
        class="disabled"
        title="Career Mode"
        :to="{ name: 'career-mode'}"
        :icon="icons.mdiBookAccountOutline"
      ></nav-menu-link>
      <nav-menu-link
        title="Arcade"
        class="disabled"
        :to="{ name: 'fight-arcade'}"
        :icon="icons.mdiGamepadVariantOutline"
      ></nav-menu-link>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiHomeOutline,
  mdiAlphaTBoxOutline,
  mdiEyeOutline,
  mdiCalendar,
  mdiAccountCashOutline,
  mdiTrophy,
  mdiTable,
  mdiFileOutline,
  mdiFormSelect,
  mdiAccountCogOutline,
  mdiAccountSearchOutline,
  mdiAccountGroup,
  mdiGamepadVariantOutline,
  mdiFormatListBulletedTriangle,
  mdiBookAccountOutline,
  mdiRefresh,
} from '@mdi/js'
import NavMenuLink from './components/NavMenuLink.vue'

export default {
  components: {
    NavMenuLink,
  },
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: null,
    },
  },
  setup() {
    return {
      calendarIcon: require('@/assets/images/misc/calendar-icon.png'),
      icons: {
        mdiHomeOutline,
        mdiAlphaTBoxOutline,
        mdiEyeOutline,
        mdiCalendar,
        mdiAccountCashOutline,
        mdiTable,
        mdiFileOutline,
        mdiFormSelect,
        mdiAccountCogOutline,
        mdiTrophy,
        mdiAccountSearchOutline,
        mdiAccountGroup,
        mdiGamepadVariantOutline,
        mdiFormatListBulletedTriangle,
        mdiBookAccountOutline,
        mdiRefresh,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

// ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
.app-logo {
  transition: all 0.18s ease-in-out;

  .v-navigation-drawer--mini-variant & {
    transform: translateX(-4px);
  }
}

@include theme(app-navigation-menu) using($material) {
  background-color: map-deep-get($material, 'background');
}

.app-navigation-menu {
  .v-list-item {
    &.vertical-nav-menu-link {
      ::v-deep .v-list-item__icon {
        .v-icon {
          transition: none !important;
        }
      }
    }
  }
}

a.vertical-nav-menu-link.disabled {
  opacity: 0.7;
  filter: blur(0.8px);
}
</style>
