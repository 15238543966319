<template>
  <v-menu
    offset-y
    left
    nudge-bottom="14"
    min-width="230"
    content-class="user-profile-menu-content"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-avatar
        size="35"
        v-bind="attrs"
        v-on="on"
      >
        <v-img
          :src="photoURL||`https://avatars.dicebear.com/api/bottts/${user.uid}.svg`"
          lazy-src="https://www.fantasykombat.com/favicon-32x32.png"
        >
        </v-img>
      </v-avatar>
    </template>
    <v-list>
      <div class="pb-3 pt-2">
        <div
          class="d-inline-flex flex-column justify-center ms-3"
          style="vertical-align:middle"
        >
          <span class="text--primary font-weight-semibold mb-n1 mb-2">
            {{ displayName }}
          </span>
          <small class="text--disabled">
            {{ email }}
          </small>
        </div>
      </div>

      <v-row class="row">
        <v-col>
          <p class="text-xs ml-3 mb-4">
            White Belt
          </p>
        </v-col>
        <v-col>
          <v-img
            :src="belt"
            max-width="20"
            class="mx-auto mr-12 mt-1 float-right"
          >
          </v-img>
        </v-col>
      </v-row>

      <v-divider></v-divider>
      <!-- Settings -->
      <router-link
        to="/account-settings"
      >
        <v-list-item link>
          <v-list-item-icon class="me-2">
            <v-icon size="22">
              {{ icons.mdiCogOutline }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              Settings
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </router-link>
      <router-link
        to="/powerups"
      >
        <v-list-item link>
          <v-list-item-icon class="me-2">
            <v-icon size="22">
              {{ icons.mdiPlusBoxMultipleOutline }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              Strike PowerUps
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </router-link>

      <!-- Pricing -->
      <router-link
        to="/connect-wallet"
      >
        <v-list-item link>
          <v-list-item-icon class="me-2">
            <v-icon size="22">
              {{ icons.mdiWalletPlusOutline }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              Dapper Account
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </router-link>
      <a
        href="https://support.fantasykombat.com/en/article/how-to-play-fantasykombat-1jf829i/"
        target="_blank"
      >
        <v-list-item link>
          <v-list-item-icon class="me-2">
            <v-icon size="22">
              {{ icons.mdiHelpCircleOutline }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              How to play
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </a>

      <a href="https://support.fantasykombat.com/" target="_blank"
      >
        <v-list-item link>
          <v-list-item-icon class="me-2">
            <v-icon size="22">
              {{ icons.mdiLifebuoy }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              Support
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </a>

      <v-divider class="my-2"></v-divider>

      <!-- Logout -->
      <v-list-item
        link
        @click="logout"
      >
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiLogoutVariant }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import {mapState} from 'vuex'
import {
  mdiAccountOutline,
  mdiEmailOutline,
  mdiCheckboxMarkedOutline,
  mdiChatOutline,
  mdiCogOutline,
  mdiCurrencyUsd,
  mdiHelpCircleOutline,
  mdiLogoutVariant,
  mdiLifebuoy,
  mdiWalletPlusOutline,
  mdiPlusBoxMultipleOutline,
} from '@mdi/js'

export default {
  setup() {
    return {
      belt: require('@/assets/images/belts/white.png'),
      icons: {
        mdiAccountOutline,
        mdiEmailOutline,
        mdiCheckboxMarkedOutline,
        mdiChatOutline,
        mdiCogOutline,
        mdiCurrencyUsd,
        mdiHelpCircleOutline,
        mdiLogoutVariant,
        mdiLifebuoy,
        mdiWalletPlusOutline,
        mdiPlusBoxMultipleOutline,
      },
    }
  },
  data() {
    return {
      user: this.$store.state.user,
    }
  },
  computed: mapState({
    email: state => state.user.email,
    displayName: state => state.user.displayName,
    photoURL: state => state.user.photoURL,
  }),
  methods: {
    logout() {
      this.$store.dispatch('logout')
    },
  },
}
</script>

<style lang="scss">
.v-avatar {
  border: 2px solid #ddd;
}

.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>
