import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import * as Sentry from '@sentry/vue'
import {Integrations} from '@sentry/tracing'
import VueMeta from 'vue-meta'
import VueCookies from 'vue-cookies'
import CountryFlag from 'vue-country-flag'
import VCurrencyField from 'v-currency-field'
import VCountryRegionSelect from '@timbouc/vuetify-country-region-input'
import VueJsonToCsv from 'vue-json-to-csv'
import moment from 'moment-timezone'
import {VTextField} from 'vuetify/lib'
import VueApexCharts from 'vue-apexcharts'
import VueConfetti from 'vue-confetti'
import VueResizeText from 'vue-resize-text'
import underscore from 'vue-underscore'
import {library} from '@fortawesome/fontawesome-svg-core'
import {faUserSecret} from '@fortawesome/pro-duotone-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import './filters'

// import fighter data
/* eslint-disable-next-line no-unused-vars */
// import importJSON from '@/db/JSONimport'

library.add(faUserSecret)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('country-flag', CountryFlag)
Vue.component('v-text-field', VTextField)

Vue.config.productionTip = false
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
})
Vue.use(VCurrencyField, {
  locale: 'en',
  decimalLength: 0,
  autoDecimalMode: true,
  min: null,
  max: null,
  defaultValue: 0,
  valueAsInteger: false,
  allowNegative: false,
})
Vue.use(VueCookies)
Vue.use(VCountryRegionSelect)
Vue.use(VueJsonToCsv)
Vue.use(VueApexCharts)
Vue.use(VueConfetti)
Vue.use(VueResizeText)
Vue.use(underscore)
Vue.use(require('vue-moment'), {moment})

Vue.prototype.$baseURL = process.env.VUE_APP_BASE_URL
Vue.prototype.$vmURL = process.env.VUE_APP_VM_BASE
Vue.prototype.$version = process.env.VUE_APP_GIT_HASH

// console.log(process.env.VUE_APP_ENV)

Vue.mixin({
  data() {
    return {
      tz: moment.tz(moment.tz.guess()).zoneAbbr(),

      // staticURL: 'https://storage.googleapis.com/fk-public',
      staticURL: '/fighters',
      fighterImagesURL: process.env.VUE_APP_STATIC,
    }
  },
})

Sentry.init({
  Vue,
  dsn: 'https://dd48566804f544639ed0cf9244293024@o1079371.ingest.sentry.io/6084050',
  environment: process.env.VUE_APP_ENV,
  release: 'FantasyKombat@1.1.0',
  hooks: ['create', 'mount', 'update'],
  logErrors: true,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ['localhost', 'fantasykombat.com', /^\//],
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
})

new Vue({
  router,
  store,
  vuetify,
  created() {
    const userString = localStorage.getItem('user')
    if (userString) {
      const userData = JSON.parse(userString)
      this.$store.commit('SET_USER_DATA', userData)
      Sentry.setUser({email: userData.email, id: userData.uid})
    }
  },
  data() {
    return {isMobile: false}
  },
  mounted() {
    this.onResize()
  },
  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 912 || window.innerHeight < 660
      if (this.isMobile) {
        document.body.classList.remove('desktop')
        document.body.classList.add('mobile')
      } else {
        document.body.classList.remove('mobile')
        document.body.classList.add('desktop')
      }
    },
  },
  render: h => h(App),
}).$mount('#app')
